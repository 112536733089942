<template>
  <div class="room-edit">
    <el-button type="text" size="medium" @click="onEdit" v-has="'resource_room_edit'">编辑</el-button>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 位置名称 -->
        <el-form-item label="位置名称" label-width="120px" prop="roomName">
          <el-input v-model.trim="params.roomName" autocomplete="off" maxlength="20" placeholder="请填写位置名称"></el-input>
        </el-form-item>
        <!-- 位置价格 -->
        <el-form-item label="位置价格" label-width="120px" prop="price">
          <el-input v-model="params.price" autocomplete="off" maxlength="8" placeholder="请填写位置价格">
            <template slot="prefix">
              <div style="line-height: 40px;">￥</div>
            </template>
            <template slot="append">{{priceUnit[params.pricingType-1] }}</template>
          </el-input>
          <div class="high-price">
            <HighPrice :roomId="roomId"></HighPrice>
          </div>
        </el-form-item>
        <el-form-item label="按天价格" label-width="120px" prop="priceDay"  v-if="topPricingUnit.includes('2')">
          <el-input v-model="params.priceDay" autocomplete="off" maxlength="8" placeholder="请填写位置价格">
            <template slot="prefix">
              <div style="line-height: 40px;">￥</div>
            </template>
            <template slot="append">{{ priceUnit[1] }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="按月价格" label-width="120px" prop="priceMonth" v-if="topPricingUnit.includes('3')">
          <el-input v-model="params.priceMonth" autocomplete="off" maxlength="8" placeholder="请填写位置价格">
            <template slot="prefix">
              <div style="line-height: 40px;">￥</div>
            </template>
            <template slot="append">{{ priceUnit[2] }}</template>
          </el-input>
        </el-form-item> 
        <!-- 价格描述 -->
        <el-form-item label="价格描述" label-width="120px" prop="priceDescribe" style="margin-bottom: 0">
          <el-input v-model.trim="params.priceDescribe" autocomplete="off" maxlength="6"
            placeholder="价格描述最多输入6个字"></el-input>
        </el-form-item>
        <p class="tips" style="margin: 0.1rem 0 0.1rem 1.2rem">
          Tips:小程序显示位置价格的地方优先显示价格描述
        </p>
        <!-- 优惠信息 -->
        <el-form-item label="优惠信息" label-width="120px" prop="discountInformation">
          <el-input v-model="params.discountInformation" autocomplete="off" maxlength="12"
            placeholder="优惠信息最多输入12个字"></el-input>
        </el-form-item>
        <!-- 房源标签 -->
        <el-form-item label="标签" label-width="120px" prop="label">
          <el-input v-model.trim="tagInput" autocomplete="off" maxlength="6" placeholder="最多输入6个字">
            <template slot="append">
              <el-button style="border-radius: 0" type="primary" plain @click="onTagAdd">
                <i class="el-icon-plus"></i> 新建标签
              </el-button>
            </template>
          </el-input>
          <div class="tags" v-if="tagArr.length">
            <el-tag class="tag-item" type="warning" v-for="(item,index) in tagArr" :key="index" closable @close="onTagDel(item)">
              {{ item }}
            </el-tag>
          </div>
        </el-form-item>
        <!-- 位置图片 -->
        <el-form-item label="位置图片" label-width="120px">
          <p class="tip">(&nbsp;限制上传9张，500kb以内的图片&nbsp;)</p>
        </el-form-item>
        <!-- 图片列表 -->

        <ul class="imgs">
          <draggable v-model="fileList" filter=".forbid" animation="300" @end="onMove">
            <transition-group>
              <li v-for="(item, index) in fileList" class="upload-img" :key="index">
                <img :src="item.url" />
                <div class="img-del" @click="onImgDel(item)">
                  <i class="el-icon-delete"></i>
                </div>
              </li>
            </transition-group>
          </draggable>
          <li class="upload-btn" v-show="upBtnShow">
            <i class="el-icon-plus uploader-icon" @click="onUpload()"></i>
          </li>
        </ul>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="2"></UpLoad>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { rules } from "@/db/rules";
import { getRoomInfo, getRoomEdit } from "@/api/resource/room";
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
import HighPrice from "./HighPrice.vue";
export default {
  name: "RoomEditBtn",
  props: ["roomId"],
  components: { UpLoad, HighPrice, draggable },
  data() {
    return {
      rules,
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      fileList: [], //图片列表
      tagInput: "", //标签输入框值
      tagArr: [],
      params: {
        roomName: "", //名称
        price: "", //价格
        priceDescribe: "", //价格描述
        discountInformation: "", //优惠信息
        label: "", //房源标签

        picUrl: "", //图片路径
        pictures: "", //图片id
      },
      priceUnit:['元/小时','元/天','元/月'],
      pricingType:1,
      topPricingUnit:[]

    };
  },
  watch: {
    fileList(val) {
      if (val) {
        let arr = [];
        for (let i of val) {
          arr = [...arr, i.attId];
        }
        return (this.params.pictures = arr.toString());
      } else {
        return [];
      }
    },
  },
  computed: {
    upBtnShow() {
      if (this.fileList && this.fileList.length > 9) {
        return false;
      } else if (!this.fileList || this.fileList.length < 9) {
        return true;
      }
    },
    maxlimit() {
      return 9 - this.fileList.length;
    },
  },
  methods: {
    //
    onMove(e) {
      console.log(1, e);
      console.log(2, this.fileList);
    },
    // 【请求】位置详情
    getRoomInfo() {
      let data = {
        roomId: this.roomId,
      };
      getRoomInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params = res.data;
          this.topPricingUnit=res.data.topPricingUnit.split(',');
        
          if (res.data.fileList) {
            this.fileList = res.data.fileList;
          }
          if (this.params.label) {
            this.tagArr = this.params.label.split(",");
          } else {
            this.tagArr = [];
          }
        }
      });
    },

    // 【请求】编辑
    getRoomEdit() {
      let data = this.params;
      data.label = this.tagArr.toString();
      getRoomEdit(data).then((res) => {
        this.editShow = false;
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$emit("reload");
        }
      });
    },

    // 【监听】编辑
    onEdit() {
      this.editShow = true;
      this.editTitle = "编辑位置";
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
      this.getRoomInfo();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getRoomEdit();
        } else {
          return false;
        }
      });
    },

    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.fileList = [...this.fileList, ...res.data];
        }
      });
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.pictures = fileIds;
      this.getFileList(fileIds);
    },

    // 【监听】添加标签
    onTagAdd() {
      if (!this.tagInput)
        return this.$message({
          message: "请填写标签内容",
          type: "warning",
        });
      if (this.tagInput.length > 6)
        return this.$message({
          message: "标签最多输入6个字符",
          type: "warning",
        });
      this.tagArr.push(this.tagInput);
      this.tagInput = "";
    },

    onTagDel(item) {
      this.tagArr.splice(this.tagArr.indexOf(item), 1);
    },

    onImgDel(item) {
      this.$confirm("你确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.fileList.splice(this.fileList.indexOf(item), 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.room-edit {
  display: inline-flex;
  margin: 0 0.05rem;
}

.el-form-item__content {
  width: 100%;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
}

.upload-img {
  display: inline-block;
  border: 1px dashed #d9d9d9;
  margin: 0 0.1rem 0.1rem 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: relative;

  >img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.upload-btn {
  display: inline-block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.high-price {
  position: absolute;
  right: -40px;
  top: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.1rem;
}

.tag-item {
  margin: 0 0.05rem 0.05rem 0;
}

.imgs {
  width: 3.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.img-del {
  width: 1rem;
  height: 1rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;

  >.el-icon-delete {
    font-size: 28px;
    color: #fff;
  }
}

.upload-img:hover .img-del {
  visibility: visible;
}

.tip {
  color: red;
  display: flex;
}

.tips {
  color: #999;
  font-size: 0.12rem;
  display: flex;
}
</style>